import { useContext } from "react";
import styled from "styled-components";
import Anchor from "~/components/Anchor";
import BigCopy from "~/components/BigCopy";
import Block, { BlockContent } from "~/components/Block";
import { Decal } from "~/components/Decal";
import Divider from "~/components/Divider";
import { Text } from "~/components/Text";
import { getCommonSliceProps, getQuoteProps, getRichText } from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";
import { ThemeContext } from "~/lib/ThemeContext";
import { parseFontStyle, parseFontWeight, smallerThan } from "~/styles/theme";

const Inner = styled.div<any>`
  padding: 0 ${(p) => p.padding}rem;
  ${(p) => smallerThan(p.theme.breakpoints.small)} {
    padding: 0 ${(p) => parseInt(p.padding) / 2}rem;
  }
`;

function BigCopySlice(props) {
  const currentTheme = useContext(ThemeContext);

  const {
    spacingTop = 0,
    spacingBottom = 6,

    color = currentTheme.foreground,
    backgroundColor = currentTheme.background,
    highlightColor = currentTheme.highlight,
    animateIn = false,

    text,
    anchorText,
    anchorPreText,
    anchorId,
    size = "3vw",
    fontWeight = "400",
    fontStyle = "",

    renderAsQuote,
    quoteSource,
    quoteSourceAlignment,
    quoteDecal,
    quoteDecalOutline,
    quoteDecalPlacement,
  } = props;

  const quoteSourceTextColor = props.quoteSourceTextColor ?? color;

  return (
    <Block
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      backgroundColor={backgroundColor}
      color={color}
    >
      <BlockContent>
        <>
          {anchorText && (
            <>
              <Anchor id={anchorId} pre={anchorPreText} color={color}>
                {anchorText}
              </Anchor>
              <Divider color={color} />
            </>
          )}
          <Inner padding={renderAsQuote ? 2 : 0}>
            {renderAsQuote &&
              quoteDecal &&
              ["top_left", "both"].includes(quoteDecalPlacement) && (
                <Decal
                  size={size}
                  color={color}
                  highlightColor={highlightColor}
                  outline={quoteDecalOutline}
                >
                  {quoteDecal}
                </Decal>
              )}
            <Inner padding={renderAsQuote ? 6 : 0}>
              <BigCopy
                size={size}
                color={color}
                highlightColor={highlightColor}
                fontWeight={fontWeight}
                fontStyle={fontStyle}
                quote={renderAsQuote}
              >
                {text}
              </BigCopy>
              {renderAsQuote && (
                <>
                  {quoteSource && (
                    <Text
                      as="div"
                      color={quoteSourceTextColor}
                      fontWeight={fontWeight}
                      fontStyle={fontStyle}
                      style={{
                        textAlign: quoteSourceAlignment,
                        fontSize: `${parseInt(size) * 0.65}vw`,
                      }}
                    >
                      {quoteSource}
                    </Text>
                  )}
                  {["bottom_right", "both"].includes(quoteDecalPlacement) && (
                    <Decal
                      size={size}
                      color={color}
                      highlightColor={highlightColor}
                      outline={quoteDecalOutline}
                    >
                      {quoteDecal}
                    </Decal>
                  )}
                </>
              )}
            </Inner>
          </Inner>
        </>
      </BlockContent>
    </Block>
  );
}

export function mapSliceToProps(slice) {
  const { primary, items } = slice;

  // console.log(primary.spacing_top, primary.spacing_bottom);

  const props = {
    ...getCommonSliceProps(slice),
    text: getRichText(primary.text),
    fontStyle: parseFontStyle(primary.font_style, "Normal"),
    fontWeight: parseFontWeight(primary.font_weight, "Black"),
    ...getQuoteProps(primary),
  };

  return props;
}

export default connectSlice(mapSliceToProps)(BigCopySlice);
