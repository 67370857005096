import styled from "styled-components";
import { smallerThan } from "~/styles/theme";

export const Decal = styled.span<any>`
  p {
    margin: 0;
    padding: 0;
    -webkit-text-stroke: 0.02em;

    font-size: ${(p) => Number.parseFloat(p.size) * 2.6}vh;
    color: ${(p) => p.color || p.theme.colors.dark};
    font-weight: ${(p) => p.fontWeight || "400"};
    font-family: "AIAIAI${(p) => p.fontStyle || ""}";
    line-height: 1;

    ${(p) => smallerThan(p.theme.breakpoints.small)} {
      font-size: 10vw;
    }
    position: relative;
    b,
    strong {
      font-weight: inherit;
      color: ${(p) => p.highlightColor || p.theme.colors.primary};
      ${(p) =>
        p.outline &&
        `
      color: ${(p) => p.highlightColor || p.theme.colors.primary};
      fill-color: transparent;
      -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
      -webkit-text-stroke: 6px ${(p) => p.highlightColor || p.theme.colors.primary};
      `}
    }
  }
`;
